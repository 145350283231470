import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import jesus from "../../assets/images/jesus.jpg";
import bautismo from "../../assets/images/bautismo.jpg";
import iglesia from "../../assets/images/iglesia.jpg";
import unicidad from "../../assets/images/unicidad.jpg";

const Doctrina = () => {
  return (
    <>
      <div className="header-doctrina text-center text-white">
        <Container>
          <h1 className="display-1">Doctrina</h1>
        </Container>
      </div>
      <Container>
        <Row xs={1} md={2} className="g-2 mb-4">
          <Col className="d-flex align-items-stretch">
            <Card>
              <Card.Img variant="top" src={jesus} />
              <Card.Body>
                <Card.Title className="fs-3">Jesús es:</Card.Title>
                <Card.Text>
                  <ul>
                    <li>El Verbo de Dios.</li>
                    <li>El Hijo de Dios.</li>
                    <li>El Primogénito (heredero) de toda creación.</li>
                    <li>Dios manifestado en carne.</li>
                    <li>El Señor y el Espíritu.</li>
                    <li>La paga por nuestros pecados (el rescate).</li>
                    <li>El verdadero Dios.</li>
                    <li>La imagen misma de Su sustancia.</li>
                    <li>El Rey de reyes y Señor de señores.</li>
                    <li>La Cabeza de la Iglesia.</li>
                    <li>La Resurrección y la vida.</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="d-flex align-items-stretch">
            <Card>
              <Card.Img variant="top" src={bautismo} />
              <Card.Body>
                <Card.Title className="fs-3">El bautismo es:</Card.Title>
                <Card.Text>
                  <ul>
                    <li>La confesión de una nueva vida en Cristo.</li>
                    <li>
                      La sepultura del hombre de pecado en agua y Espíritu.
                    </li>
                    <li>En el nombre de Jesús Cristo.</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="d-flex align-items-stretch">
            <Card>
              <Card.Img variant="top" src={iglesia} />
              <Card.Body>
                <Card.Title className="fs-3">La iglesia es:</Card.Title>
                <Card.Text>
                  <ul>
                    <li>El cuerpo de Cristo.</li>
                    <li>La Ecclesia, la asamblea convocada por Cristo.</li>
                    <li>
                      La sociedad fundada y establecida por Jesús Cristo para
                      recibir, establecer y propagar sus doctrinas y ordenanzas.
                    </li>
                    <li>
                      La comunidad de Cristianos, unidos bajo una forma de
                      gobierno por la profesión de la misma fe y la observancia
                      de los mismos rituales y ceremonias.
                    </li>
                    <li>Columna y baluarte de la Verdad.</li>
                    <li>
                      La institución para el &quot;cuidado de las almas&quot; y
                      el &quot;derecho a los diezmos&quot;.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="d-flex align-items-stretch">
            <Card>
              <Card.Img variant="top" src={unicidad} />
              <Card.Body>
                <Card.Title className="fs-3">La unicidad es:</Card.Title>
                <Card.Text>
                  <ul>
                    <li>Dios es uno, único e indivisible.</li>
                    <li>
                      La manifestación del Padre en la persona de Su Hijo Jesús
                      el Cristo; quien vivió en perfecta armonía y unicidad con
                      el Padre.
                    </li>
                    <li>
                      La manifestación del Padre y Su Hijo en la Iglesia;
                      quienes siendo muchos somos un solo cuerpo, evidente en la
                      manifestación de los dones, el fruto y las obras del
                      Espíritu Santo en la Iglesia.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Doctrina;
