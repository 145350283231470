import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

const eventsAdapter = createEntityAdapter({
  sortComparer: (a, b) =>
    a.completed === b.completed ? 0 : a.completed ? 1 : -1,
});

const initialState = eventsAdapter.getInitialState();

export const eventsApiService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllEvents: builder.query({
      query: () => ({
        url: "events/all",
        validateStatus: (response, result) => {
          return (response.status === 200) && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedEvents = responseData.map((event) => {
          event.id = event._id;
          return event;
        });
        return eventsAdapter.setAll(initialState, loadedEvents);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Event", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Event", id })),
          ];
        } else {
          return [{ type: "Event", id: "LIST" }];
        }
      },
    }),
    getEventAttendance: builder.query({
      query: ({ id }) => ({
        url: "/events/" + id + "/attendance",
        validateStatus: (response, result) => {
          return (response.status === 200) && !result.isError;
        }
      }),
      transformResponse: (responseData) => {
        const attendance = responseData
        return eventsAdapter.setAll(initialState, attendance)
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Event", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Event", id })),
          ];
        } else {
          return [{ type: "Event", id: "LIST" }]
        }
      }
    }),
    createEvent: builder.mutation({
      query: (initialEventData) => ({
        url: "/events",
        method: "POST",
        body: {
          ...initialEventData
        }
      }),
      invalidatesTags: [{ type: "Event", id: "LIST" }]
    })
  }),
});

export const { 
  useGetAllEventsQuery,
  useGetEventAttendanceQuery,
  useCreateEventMutation
} = eventsApiService;

export const selectEventsResult =
  eventsApiService.endpoints.getAllEvents.select();

const selectEventsData = createSelector(
  selectEventsResult,
  (eventsResult) => eventsResult.data
);

export const {
  selectAll: selectAllEvents,
  selectById: selectEventById,
  selectIds: selectEventIds,
} = eventsAdapter.getSelectors(
  (state) => selectEventsData(state) ?? initialState
);
