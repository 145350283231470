import React from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useGetAllEventsQuery } from "../../../app/api/services/eventsApiService";

import AttendanceTable from "./AttendanceTable";

const EventAttendance = () => {
  const { id } = useParams();

  const { event } = useGetAllEventsQuery("eventsList", {
    selectFromResult: ({ data }) => ({
      event: data?.entities[id],
    }),
  });

  return (
    event ? (
      <Container className="mt-4">
        <h1 className="text-primary">{event.name}</h1>
        <AttendanceTable attendanceID={event._id} />
      </Container>
    ) : (
      <p>Información no disponible</p>
    )
  )
}

export default EventAttendance;