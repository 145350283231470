import React, { useState } from "react";
import { createEventMutation } from "../../../app/api/services/eventsApiService";
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import dayjs from "dayjs";
import { es } from "dayjs/locale/es";
import useTitle from "../../../hooks/useTitle";
import usePersist from "../../../hooks/usePersist";

import EventsTable from "./EventsTable";
import CreateEventForm from "./CreateEventForm";

const Events = () => {
  useTitle("Eventos");
  dayjs.locale("es");

  const [openEventModal, setOpenEventModal] = useState(false);

  const toggleEventModal = () => {
    setOpenEventModal(!openEventModal);
  };

  return (
    <>
      <Container className="mt-4">
        <Row>
          <Col>
            <h1 className="text-primary">Eventos</h1>
          </Col>
          <Col>
            <Button
              variant="primary float-end"
              onClick={() => toggleEventModal()}
            >
              Crear Evento
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <EventsTable />
          </Col>
        </Row>
      </Container>

      {/* Modal for creating event */}
      <Modal
        show={openEventModal}
        onHide={setOpenEventModal}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">Crear Evento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateEventForm hideModal={toggleEventModal} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Events;
