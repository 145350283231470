import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import congregacion from "../../assets/images/congregacion.jpg";
import juntaLocal from "../../assets/images/junta_local.jpg";

const Nosotros = () => {
  return (
    <>
      <div className="header-nosotros text-center text-white">
        <Container>
          <h1 className="display-1">Nosotros</h1>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <p>
              Somos la Iglesia Pentecostal Unida Latinoamericana, Inc. (IPUL) en
              el area sur de Chicago; predicamos lo que la Biblia enseña; el
              evangelio de nuestro Señor Jesucristo y el plan de salvación, asi
              mismo las doctrinas fundamentales de la iglesia primitiva. Creemos
              que Jesús es Dios manifestado en carne como lo muestra la palabra
              en Juan 1:1-14 y que de esa manera Jesús es el único nombre
              revelado a los hombres en el cual hay perdón de pecados, por lo
              cual debe ser invocado sobre nosotros al momento de bautizarnos,
              como lo dice Hechos 2:38.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <img className="img-fluid w-100 pb-4" src={congregacion} />
            <img className="img-fluid w-100 pb-4" src={juntaLocal} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Nosotros;
