import React from "react";
import { Table } from "react-bootstrap";
import { useGetEventAttendanceQuery } from "../../../app/api/services/eventsApiService";

const AttendanceTable = ({ attendanceID }) => {
  var {
    data: members,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetEventAttendanceQuery({ id: attendanceID });

  if (isSuccess) {
    members = Object.values(members.entities)[0];
  }

  const addMember = () => {
    console.log("Cesar was here");
  };

  return (
    <>
      {members && (
        <Table bordered striped responsive>
          <thead>
            <tr>
              <th className="num-col-width">#</th>
              <th>Nombre</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{member.firstName + " " + member.lastName}</td>
              </tr>
            ))}
            <tr onClick={() => addMember()}>
              <td>(+)</td>
              <td>Agregar</td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  );
};

export default AttendanceTable;
