import React from "react";
import { store } from "../../app/store";
// import { notesApiSlice } from "../notes/notesApiSlice";
// import { usersApiSlice } from "../users/usersApiSlice";
import { membersApiService } from "../../app/api/services/membersApiService";
import { eventsApiService } from "../../app/api/services/eventsApiService";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const Prefetch = () => {
  useEffect(() => {
    // store.dispatch(notesApiSlice.util.prefetch("getNotes", "notesList", { force: true }));
    // store.dispatch(usersApiSlice.util.prefetch("getUsers", "usersList", { force: true }));
    store.dispatch(membersApiService.util.prefetch("getAllMembers", "usersList", { force: true }));
    store.dispatch(eventsApiService.util.prefetch("getAllEvents", "usersList", { force: true }));
  }, []);

  return <Outlet />;
};

export default Prefetch;