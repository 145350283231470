import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import placeholder from "../../assets/images/placeholder.jpg";
import damas from "../../assets/images/damas.jpg";
import caballeros from "../../assets/images/caballeros.jpg";
import jovenes from "../../assets/images/jovenes.jpg";
import escuelaDominical from "../../assets/images/escuela_dominical.jpg";
import oracionMision from "../../assets/images/oracion_mision.jpg";
import alabanza from "../../assets/images/alabanza.jpg";

const Ministerios = () => {
  const cardItems = [
    {
      label: "Junta Local",
      image: placeholder,
    },
    {
      label: "Damas",
      image: damas,
    },
    {
      label: "Caballeros",
      image: caballeros,
    },
    {
      label: "Jóvenes",
      image: jovenes,
    },
    {
      label: "Escuela Dominical",
      image: escuelaDominical,
    },
    {
      label: "Oración & Misión",
      image: oracionMision,
    },
    {
      label: "Alabanza",
      image: alabanza,
    },
    {
      label: "Ujieres",
      image: placeholder,
    },
    {
      label: "Multimedia",
      image: placeholder,
    },
  ];

  return (
    <>
      <div className="header-ministerios text-center text-white">
        <Container>
          <h1 className="display-1">Ministerios</h1>
        </Container>
      </div>
      <Container>
        <Row xs={1} md={2} lg={3} className="g-2 mb-4">
          {cardItems.map((item, index) => (
            <Col key={index}>
              <Card>
                <Card.Img variant="top" src={item.image} />
                <Card.Body className="p-2">
                  <Card.Title className="text-center mb-0 fs-3">{item.label}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Ministerios;
