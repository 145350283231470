import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

const membersAdapter = createEntityAdapter({
  sortComparer: (a, b) =>
    a.completed === b.completed ? 0 : a.completed ? 1 : -1,
});

const initialState = membersAdapter.getInitialState();

export const membersApiService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllMembers: builder.query({
      query: () => ({
        url: "/members/all",
        validateStatus: (response, result) => {
          return (response.status === 200) && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedMembers = responseData.map((member) => {
          member.id = member._id;
          return member;
        });
        return membersAdapter.setAll(initialState, loadedMembers);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Member", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Member", id })),
          ];
        } else {
          return [{ type: "Member", id: "LIST" }];
        }
      },
    }),
  }),
});

export const { useGetAllMembersQuery } = membersApiService;

export const selectUsersResult =
  membersApiService.endpoints.getAllMembers.select();

const selectUsersData = createSelector(
  selectUsersResult,
  (usersResult) => usersResult.data
);

export const {
  selectAll: selectAllUsers,
  selectById: selectUserById,
  selectIds: selectUserIds,
} = membersAdapter.getSelectors(
  (state) => selectUsersData(state) ?? initialState
);
