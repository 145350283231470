import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Home from "./pages/public/Home";
import Nosotros from "./pages/public/Nosotros";
import Doctrina from "./pages/public/Doctrina";
import Ministerios from "./pages/public/Ministerios";
import Pastor from "./pages/public/Pastor";
import Contactanos from "./pages/public/Contactanos";
import Login from "./pages/members/Login";
import Admin from "./pages/members/Admin";
import Events from "./pages/members/Events/Events";
import EventAttendance from "./pages/members/Events/EventAttendance";

// import Public from "./components/Public";
// import Login from "./features/auth/Login";
// import DashLayout from "./components/Layout/DashLayout";
// import Welcome from "./features/auth/Welcome";
// import NotesList from "./features/notes/NotesList";
// import UsersList from "./features/users/UsersList";
// import EditUser from "./features/users/EditUser";
// import NewUserForm from "./features/users/NewUserForm";
// import EditNote from "./features/notes/EditNote";
// import NewNote from "./features/notes/NewNote";
import Prefetch from "./features/auth/Prefetch";
import PersistLogin from "./features/auth/PersistLogin";
import RequireAuth from "./features/auth/RequireAuth";
import useTitle from "./hooks/useTitle";
import { ROLES } from "./config/roles";

function App() {
  useTitle("IPUL Chicago Sur");

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="nosotros" element={<Nosotros />} />
        <Route path="doctrina" element={<Doctrina />} />
        <Route path="ministerios" element={<Ministerios />} />
        <Route path="pastor" element={<Pastor />} />
        <Route path="contactanos" element={<Contactanos />} />
        <Route path="sesion" element={<Login />} />

        <Route path="*" element={<Home />} />

        <Route element={<PersistLogin />}>
          <Route
            element={<RequireAuth allowedPermissions={[...Object.values(ROLES)]} />}
          >
            <Route element={<Prefetch />}>
              <Route path="admin" element={<Admin />} />
              <Route path="eventos">
                <Route path="" element={<Events />} />
                <Route path=":id" element={<EventAttendance />} />
              </Route>

              {/* MANAGER AND ADMIN ONLY ROUTES */}
              {/* <Route
                element={
                  <RequireAuth allowedPermissions={[ROLES.Manager, ROLES.Admin]} />
                }
              >
                <Route path="users">
                  <Route index element={<UsersList />} />
                  <Route path=":id" element={<EditUser />} />
                  <Route path="new" element={<NewUserForm />} />
                </Route>
              </Route> */}

            </Route>
          </Route>
        </Route>
      </Route>

      {/* <Route index element={<Public />} /> */}
      {/* <Route path="login" element={<Login />} /> */}

      {/* <Route element={<PersistLogin />}>
        <Route
          element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}
        >
          <Route element={<Prefetch />}>
            <Route path="dash" element={<DashLayout />}>
              <Route index element={<Welcome />} />

              <Route
                element={
                  <RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin]} />
                }
              >
                <Route path="users">
                  <Route index element={<UsersList />} />
                  <Route path=":id" element={<EditUser />} />
                  <Route path="new" element={<NewUserForm />} />
                </Route>
              </Route>

              <Route path="notes">
                <Route index element={<NotesList />} />
                <Route path=":id" element={<EditNote />} />
                <Route path="new" element={<NewNote />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route> */}
    </Routes>
  );
}

export default App;
