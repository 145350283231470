import React, { useState } from "react";
import { useCreateEventMutation } from "../../../app/api/services/eventsApiService";
import { Button, Form, Alert } from "react-bootstrap";

const CreateEventForm = ({ hideModal }) => {
  const ministriesArray = [
    "",
    "Junta Local",
    "Damas",
    "Caballeros",
    "Jóvenes",
    "Escuela Dominical",
    "Oración & Misión",
    "Alabanza",
    "Ujieres",
    "Multimedia",
    "English",
  ];

  const [name, setName] = useState("");
  const [ministry, setMinistry] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [createEvent, { isLoading }] = useCreateEventMutation();

  const handleNameInput = (e) => {
    var input = e.target.value;
    setName(input);
  };

  const handleMinistryInput = (e) => {
    var input = e.target.value;
    setMinistry(input);
  };

  const submitCreateEventForm = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    try {
      const response = await createEvent({ name, ministry });
      if (response.error) {
        setErrorMessage(response.error.data.message);
      } else {
        setName("");
        setMinistry("");
        hideModal();
      }
    } catch (err) {
      setErrorMessage(err.data.message);
    }
  };

  return (
    <Form onSubmit={submitCreateEventForm}>
      {errorMessage && (
        <Alert variant="danger" className="text-center">
          {errorMessage}
        </Alert>
      )}
      <Form.Group className="mb-3">
        <Form.Label htmlFor="nameInput">Nombre</Form.Label>
        <Form.Control
          type="text"
          id="nameInput"
          onChange={handleNameInput}
          value={name}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="ministryInput">Ministerio</Form.Label>
        <Form.Select
          id="ministryInput"
          onChange={handleMinistryInput}
          value={ministry}
        >
          {ministriesArray.map((ministry, index) => (
            <option key={index} value={ministry}>
              {ministry}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Button
        variant="primary"
        className="ml-2"
        type="submit"
        disabled={name.trim() === "" || ministry.trim() === ""}
      >
        Crear Evento
      </Button>
    </Form>
  );
};

export default CreateEventForm;
