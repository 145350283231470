import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RequireAuth = ({ allowedPermissions }) => {
  const location = useLocation();
  const { permission } = useAuth();

  const content = allowedPermissions.includes(permission) ? (
    <Outlet />
  ) : (
    <Navigate to="/sesion" state={{ from: location }} replace />
  );

  return content;
};

export default RequireAuth;
