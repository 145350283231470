import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import pastor from "../../assets/images/pastor.jpg";

const Pastor = () => {
  return (
    <>
      <div className="header-pastor text-center text-white">
        <Container>
          <h1 className="display-1">Pastor</h1>
        </Container>
      </div>
      <Container>
        <Row xs={1} md={2}>
          <Col>
            <img className="img-fluid w-100 pb-4" src={pastor} />
          </Col>
          <Col>
            <h1 className="text-primary">Historia</h1>
            <p>
              El hermano Jorge Gutiérrez nació en Corozál (Súcre) Colombia, y ha
              vivido en los países de Honduras, Guatemala, México y actualmente
              Estados Unidos. Criado en el evangelio por sus padres, los
              misioneros Elvis & Farides Gutiérrez, entregó su vida al Señor
              desde temprana edad y fue bautizado en el glorioso nombre de Jesús
              el 1 de abril de 1999, ese mismo año fue lleno del Espíritu Santo.
              Recibió el llamado al santo ministerio en el país de México siendo
              un joven creyente de 20 años.
            </p>
            <p>
              Conoció a la hermana Viviana en el año 2009, quien era miembro de
              la Iglesia Pentecostal Unida Latinoamericana en la ciudad de
              Pompano Beach, FL y servía como líder del departamento de
              misiones. Se casaron el 12 de marzo del año 2011 en la ciudad de
              Fort Lauderdale, Florida. Tienen dos pequeños hijos: Levi y
              Abygail, ambos nacidos en la ciudad de Chicago, IL donde son
              pastores de la congregación Chicago Sur desde finales del año 2011
              hasta el presente.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Pastor;
