import React from "react";
import { Table } from "react-bootstrap";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { es } from "dayjs/locale/es";
import { useGetAllEventsQuery } from "../../../app/api/services/eventsApiService";

const EventsTable = () => {
  dayjs.locale("es");

  var {
    data: events,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllEventsQuery("eventsList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  if (isSuccess) {
    events = Object.values(events.entities);
  }

  return (
    events ? (
      <Table bordered striped responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Fecha</th>
            <th>Ministerio</th>
            <th>Asistencia</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {events.map((event, index) => (
            <tr key={event._id}>
              <td>{index + 1}</td>
              <td>{event.name}</td>
              <td>{dayjs(event.date).format("MM/DD/YYYY")}</td>
              <td>{event.ministry}</td>
              <td className="text-center">{event.attendance.length}</td>
              <td>
                <a href="/">
                  <FontAwesomeIcon icon={faPenToSquare} />
                </a>
              </td>
              <td>
                <a href={"/eventos/"+ event._id}>
                  <FontAwesomeIcon icon={faFolderOpen} />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    ) : (
      <p>No hay eventos</p>
    )
  )
};

export default EventsTable;
