import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faScrewdriverWrench,
  faHandHoldingDollar,
  faHandshakeAngle,
} from "@fortawesome/free-solid-svg-icons";
import church from "../../assets/images/church.png";

const Home = () => {
  return (
    <>
      {/* HEADER SECTION */}
      <div className="header-home text-white text-center">
        <Container>
          <Row className="align-items-center">
            <Col>
              <h1 className="display-1 mb-0">Bienvenido(a)</h1>
              <hr className="w-25 mx-auto" />
              <h4 className="text">
                Así alumbre vuestra luz delante de los hombres,{" "}
                <br className="d-none d-md-block" />
                para que vean vuestras buenas obras,{" "}
                <br className="d-none d-md-block" />y glorifiquen a vuestro
                Padre que está en los cielos.
              </h4>
              <h4>Mateo 5:16</h4>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="py-4">
        <Container>
          {/* CALL TO ACTION BUTTONS */}
          <Row xs={2} md={4} className="text-center g-2 g-md-3 g-lg-4">
            <Col>
              <a href="/nosotros" className="cards-home">
                <Card bg="gray-100">
                  <Card.Body className="px-2 py-4">
                    <FontAwesomeIcon icon={faInfo} className="display-1 mb-4" />
                    <h4 className="mb-0">Conócenos</h4>
                  </Card.Body>
                </Card>
              </a>
            </Col>
            <Col>
              <a href="/doctrina" className="cards-home">
                <Card bg="gray-100">
                  <Card.Body className="px-2 py-4">
                    <FontAwesomeIcon
                      icon={faScrewdriverWrench}
                      className="display-1 mb-4"
                    />
                    <h4 className="mb-0">Proyectos</h4>
                  </Card.Body>
                </Card>
              </a>
            </Col>
            <Col>
              <a
                href="https://cash.app/$ChicagoSurTesoreria"
                target="_blank"
                rel="noopener noreferrer"
                className="cards-home"
              >
                <Card bg="gray-100">
                  <Card.Body className="px-2 py-4">
                    <FontAwesomeIcon
                      icon={faHandHoldingDollar}
                      className="display-1 mb-4"
                    />
                    <h4 className="mb-0">Donaciones</h4>
                  </Card.Body>
                </Card>
              </a>
            </Col>
            <Col>
              <a href="/ministerios" className="cards-home">
                <Card bg="gray-100">
                  <Card.Body className="px-2 py-4">
                    <FontAwesomeIcon
                      icon={faHandshakeAngle}
                      className="display-1 mb-4"
                    />
                    <h4 className="mb-0">Involúcrate</h4>
                  </Card.Body>
                </Card>
              </a>
            </Col>
          </Row>
          {/* NUESTRA ORGANIZACION */}
          <h1 className="text-primary pt-4">Nuestra Organización</h1>
          <Row xs={1} md={2}>
            <Col>
              <p>
                Por cuanto es la voluntad de Dios sacar del mundo un pueblo
                salvo para la Gloria de su Nombre, pueblo que constituye la
                iglesia de Jesucristo, la cual debe estar edificada sobre el
                fundamento de los apóstoles y profetas, siendo la principal
                piedra del ángulo Jesucristo mismo. (Efesios 2:20; 1 Colosenses
                3:11).
              </p>
              <p>
                Por cuanto se hace necesaria la permanente comunión entre los
                miembros de la iglesia; impartir consejos y ser instruidos en la
                Palabra de Dios para la obra del ministerio, y para el ejercicio
                de los oficios espirituales provistos en la Santa Biblia.
              </p>
              <p>
                Por cuanto esta comunión establecida por Dios es sostenida por
                el Espíritu Santo, y Él es quien pone en su iglesia:
                evangelistas, pastores, misioneros, maestros, etc.
              </p>
              <p>Entonces,</p>
              <p>
                Nos reconocemos miembros del cuerpo de Jesucristo, y por lo
                tanto hermanos en la fe, todos los que profesamos los
                mandamientos establecidos por el Espíritu Santo en Hechos
                2:38-39:
              </p>
              <p className="mb-xs-3 mb-sm-0">
                &quot;Pedro les dijo: Arrepentíos, y bautícese cada uno de
                vosotros en el nombre de Jesucristo para perdón de los pecados;
                y recibiréis el don del Espíritu Santo. Porque para vosotros es
                la promesa, y para vuestros hijos y para todos los que están
                lejos; para cuantos el Señor nuestro Dios llamare.&quot;
              </p>
            </Col>
            <Col>
              <p>
                Declaramos esforzarnos para guardar la unidad del Espíritu en el
                vínculo de la paz, profesando nuestra adoración a un Señor,
                poniendo en práctica: Una fe y un bautismo, ya que tenemos un
                solo Dios y Padre de todos en todos:
              </p>
              <p>
                “Solícitos en guardar la unidad del Espíritu en el vínculo de la
                paz; un cuerpo, y un Espíritu, como fuisteis también llamados en
                una misma esperanza de vuestra vocación; un Señor, una fe, un
                bautismo, un Dios y Padre de todos, el cual es sobre todos y por
                todos y en todos.” (Efesios 4:3-6).
              </p>
              <p>
                Somos testigos del amor, la fidelidad y el poder de Dios en
                nuestras vidas. Lo que hemos alcanzado es por su misericordia y
                sentimos gratitud profunda por su gracia traducida en
                experiencias extraordinarias y verdaderas que nos llevan a dar
                de gracia lo que de gracia hemos recibido. Te invitamos a formar
                parte del cuerpo de Cristo que es su iglesia, ven y goza de la
                presencia del Señor en nuestra Casa.
              </p>
            </Col>
          </Row>
          {/* CHURCH PICTURE */}
          <Row>
            <Col>
              <img className="img-fluid w-100 pt-4" src={church} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Home;
