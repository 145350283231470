import React, { useState } from "react";
import { useGetAllMembersQuery } from "../../app/api/services/membersApiService";
import {
  Container,
  Row,
  Col,
  Table,
  Modal,
  Button,
  Image,
} from "react-bootstrap";
import { formatPhoneNumber, translatePermission } from "../../util/formatUtil";
import dayjs from "dayjs";
import { es } from "dayjs/locale/es";
import useTitle from "../../hooks/useTitle";
import { placeholders } from "../../util/placeholders";

const Admin = () => {
  useTitle("Panel de Administración");
  dayjs.locale("es");

  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState("");

  var {
    data: members,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllMembersQuery("membersList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  if (isSuccess) {
    members = Object.values(members.entities);
  }

  const toggleInfoModal = (member) => {
    setOpenInfoModal(!openInfoModal);
    setSelectedMember(member);
  };

  return (
    <>
      <Container className="mt-4">
        <Row>
          <Col>
            <h1 className="text-primary">Panel de Administración</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            {members ? (
              <Table bordered striped responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nombre</th>
                    <th>Teléfono</th>
                    <th>E-mail</th>
                    <th>Cumpleaños</th>
                    <th>Permiso</th>
                  </tr>
                </thead>
                <tbody>
                  {members.map((member, index) => (
                    <tr
                      key={member._id}
                      onClick={() => toggleInfoModal(member)}
                    >
                      <td>{index + 1}</td>
                      <td>{member.fullName}</td>
                      <td>{formatPhoneNumber(member.telephone)}</td>
                      <td>{member.email}</td>
                      <td>{dayjs(member.birthdate).format("MM/DD/YYYY")}</td>
                      <td>{translatePermission(member.permission)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>No hay miembros</p>
            )}
          </Col>
        </Row>
      </Container>
      {selectedMember && (
        <Modal
          show={openInfoModal}
          onHide={setOpenInfoModal}
          size="md"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-primary">
              Información Personal
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Image
                  roundedCircle
                  className="mb-3 member-profile"
                  fluid
                  src={
                    selectedMember.photo
                      ? selectedMember.photo
                      : placeholders.profile
                  }
                />
                <p className="text-primary mb-0">Nombre:</p>
                <p>{`${selectedMember.firstName} ${selectedMember.middleName} ${selectedMember.lastName}`}</p>
                <hr className="border-gray-100" />

                <p className="text-primary mb-0">Teléfono:</p>
                <p className="mb-1">
                  {formatPhoneNumber(selectedMember.telephone)}
                </p>
                <a href={`tel: ${selectedMember.telephone}`}>Llamar teléfono</a>
                <hr className="border-gray-100" />

                <p className="text-primary mb-0">E-mail:</p>
                <p className="mb-1">{selectedMember.email}</p>
                <a href={`mailto: ${selectedMember.email}`}>Enviar e-mail</a>
                <hr className="border-gray-100" />

                <p className="text-primary mb-0">Dirección:</p>
                <p className="mb-0">{selectedMember.address.line1}</p>
                <p className="mb-0">{selectedMember.address.line2}</p>
                <p className="mb-1">{`${selectedMember.address.city}, ${selectedMember.address.state} ${selectedMember.address.zipCode}`}</p>
                <a
                  href={`https://maps.google.com/?q=${selectedMember.fullAddress}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Abrir en Google Mapas
                </a>
                <hr className="border-gray-100" />

                <p className="text-primary mb-0">Cumpleaños:</p>
                <p className="capitalize-first">
                  {dayjs(selectedMember.birthdate)
                    .locale("es")
                    .format("MMMM D[,] YYYY")}{" "}
                  ({dayjs(new Date()).diff(selectedMember.birthdate, "years")} años)
                </p>
                <hr className="border-gray-100" />

                <p className="text-primary mb-0">Miembro desde:</p>
                <p className="capitalize-first">
                  {dayjs(selectedMember.memberSince)
                    .locale("es")
                    .format("MMMM D[,] YYYY")}
                </p>
                <hr className="border-gray-100" />

                <p className="text-primary mb-0">Permiso:</p>
                <p>{translatePermission(selectedMember.permission)}</p>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => toggleInfoModal("")}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Admin;
