export const formatPhoneNumber = (telephone) => {
  var cleaned = ("" + telephone).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

export const translatePermission = (permission) => {
  if (permission === "admin") {
    return "Admin";
  } else if (permission === "leader") {
    return "Líder";
  } else if (permission === "member") {
    return "Miembro";
  } else {
    return "?";
  }
};
