import React from "react";
import logo_black from "../../assets/images/logo_icon_black.png";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";

const Navigation = () => {
  const menuItems = [
    { label: "Nosotros", link: "/nosotros" },
    { label: "Doctrina", link: "/doctrina" },
    { label: "Ministerios", link: "/ministerios" },
    { label: "Pastor", link: "/pastor" },
    // { label: "Contáctanos", link: "/contactanos" },
  ];

  const user = useAuth();

  return (
    <Navbar bg="gray-100" expand="md" fixed="top">
      <Container>
        <Navbar.Brand href="/" className="navbar-brand">
          <img
            src={logo_black}
            height="30"
            className="d-inline-block align-top me-2"
          />
          IPUL Chicago Sur
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navigation" />
        <Navbar.Collapse id="navigation" className="justify-content-end">
          <Nav>
            {menuItems.map((item, index) => (
              <Nav.Link href={item.link} key={index} className="navbar-item">
                {item.label}
              </Nav.Link>
            ))}
            {(user.permission === "admin" || user.permission === "leader") && (
              <NavDropdown
                title={
                  <div>
                    <span style={{ marginRight: "5px" }}>
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                    <span>{user.name}</span>
                  </div>
                }
              >
                <NavDropdown.Item href="/admin">
                  Panel de Administración
                </NavDropdown.Item>
                <NavDropdown.Item href="/eventos">Eventos</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/sesion">
                  Cerrar Sesión
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
