import { useSelector } from "react-redux";
import { selectCurrentToken } from "../features/auth/authSlice";
import jwtDecode from "jwt-decode";

const useAuth = () => {
  const token = useSelector(selectCurrentToken);
  let isLeader = false;
  let isAdmin = false;
  let status = "Employee";

  if (token) {
    const decoded = jwtDecode(token);
    var { name, telephone, permission } = decoded.MemberInfo;

    isAdmin = (permission === "admin");
    isLeader = (permission === "leader");

    return { name, telephone, permission, isLeader, isAdmin };
  }

  return { name: "", username: "", permission: [], isLeader, isAdmin, status };
};

export default useAuth;
