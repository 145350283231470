import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../features/auth/authApiSlice";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/auth/authSlice";
import usePersist from "../../hooks/usePersist";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  FloatingLabel,
  Button,
  Alert,
} from "react-bootstrap";

const Login = () => {
  const [telephone, setTelephone] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [persist, setPersist] = usePersist();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, { isLoading }] = useLoginMutation();

  useEffect(() => {
    setErrorMessage("");
  }, [telephone, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPersist(true);
    try {
      const { accessToken } = await login({ telephone, password }).unwrap();
      dispatch(setCredentials({ accessToken }));
      setTelephone("");
      setPassword("");
      navigate("/admin");
    } catch (err) {
      setErrorMessage(err.data.message);
    }
  };

  const handleTelephoneInput = (e) => {
    var input = e.target.value;
    setTelephone(input);
  };

  const handlePasswordInput = (e) => {
    var input = e.target.value;
    setPassword(input);
  };

  return (
    <Container className="mt-4">
      <Row xs={1} md={2} className="justify-content-center">
        <Col>
          <Card>
            <Card.Body>
              <h1 className="text-primary">Bienvenido(a)</h1>
              <h2 className="mb-3">Iniciar Sesión</h2>
              {errorMessage && (
                <Alert variant="danger" className="text-center">
                  {errorMessage}
                </Alert>
              )}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="telephoneInput">Teléfono</Form.Label>
                  <Form.Control
                    type="text"
                    id="telephoneInput"
                    onChange={handleTelephoneInput}
                    value={telephone}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="passwordInput">Contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    id="passwordInput"
                    onChange={handlePasswordInput}
                    value={password}
                  />
                  <Form.Text muted>
                    ¿Olvidaste tu contraseña?
                  </Form.Text>
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={telephone.trim() === "" || password.trim() === ""}
                >
                  Iniciar Sesión
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
