import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="text-white">
      <div className="bg-primary py-4">
        <Container>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <h5>Contáctanos:</h5>
              <p className="mb-0">(954) 825-6472</p>
              <p className="mb-4 mb-lg-0">ipul.chicagosur@hotmail.com</p>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <h5>Visítanos:</h5>
              <p className="mb-0">6425 South Central Ave</p>
              <p className="mb-4 mb-lg-0">Chicago, IL 60638</p>
            </Col>
            <Col xs={12} lg={6}>
              <h5>Acompáñanos:</h5>
              <p className="mb-0">Servicio Dirigido por Ministerio: Martes, 7:30 PM</p>
              <p className="mb-0">Servicio de Adoración y Doctrina: Viernes, 7:30 PM</p>
              <p className="mb-0">Escuela Dominical: Domingo, 11:00 AM</p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-gray-800 py-2">
        <Container>
          <p className="mb-0">
            &copy; {currentYear} Derechos Reservados.
            <br className="d-block d-sm-none" /> Iglesia Pentecostal Unida
            Latinoamericana, Inc.
          </p>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
